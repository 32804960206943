// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home-chart-date_dateChart__gauIR {\n  width: 100%;\n  margin-bottom: 100px;\n}\n.home-chart-date_dateChart_Header__SdCCF {\n  display: flex;\n  width: 94%;\n}\n.home-chart-date_dateChart_Details__\\+7Hx\\+ {\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n  margin-left: 32px;\n}\n.home-chart-date_dateChart_DropeDownContainer__nKzrr {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/features/home/components/home-chart-local/home-chart-date.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,UAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,aAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;AADJ","sourcesContent":[".dateChart {\n  width: 100%;\n  margin-bottom: 100px;\n\n  &_Header {\n    display: flex;\n    width: 94%;\n  }\n\n  &_Details {\n    font-size: 24px;\n    font-weight: 500;\n    line-height: 32px;\n    margin-left: 32px;\n  }\n\n  &_DropeDownContainer {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateChart": "home-chart-date_dateChart__gauIR",
	"dateChart_Header": "home-chart-date_dateChart_Header__SdCCF",
	"dateChart_Details": "home-chart-date_dateChart_Details__+7Hx+",
	"dateChart_DropeDownContainer": "home-chart-date_dateChart_DropeDownContainer__nKzrr"
};
export default ___CSS_LOADER_EXPORT___;
