export const Apple = ({ ...props }) => {
  return (
    <>
      {!props.checked ? (
        <>
          <svg
            width='40'
            height='48'
            viewBox='0 0 40 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M31.1485 9.50084C29.4754 9.08326 27.7355 9.20205 25.978 9.85413C22.1232 11.2837 17.8769 11.2837 14.0221 9.85413C12.2651 9.20205 10.5251 9.08326 8.85105 9.50084C2.09537 11.1886 -1.19201 20.9116 1.36663 31.6358C3.67096 41.2939 9.81651 47.9957 15.9721 47.9947C16.6516 47.9947 17.3326 47.9129 18.0071 47.7442C19.3298 47.4084 20.6702 47.4084 22.0227 47.7519C22.6926 47.9191 23.3671 48.0003 24.042 48.0003C30.178 48.0003 36.3281 41.2975 38.6334 31.6358C41.1921 20.9116 37.9042 11.1886 31.1485 9.50084ZM36.1919 31.0264C34.0414 40.0392 27.8275 46.5547 22.5981 45.2491C20.8738 44.8109 19.1258 44.8114 17.3818 45.2542C12.1736 46.5542 5.9587 40.0392 3.80816 31.0264C1.65762 22.0131 4.23988 13.2996 9.44711 11.9991C9.89893 11.8859 10.3613 11.8299 10.8312 11.8299C11.5916 11.8299 12.3721 11.9775 13.1662 12.2716C17.5738 13.9059 22.4277 13.9064 26.8344 12.2716C28.1185 11.7949 29.3694 11.7039 30.553 11.9991C35.7597 13.2996 38.3424 22.0131 36.1919 31.0264Z'
              fill='#222122'
            />
            <path
              d='M21.5868 3.54837C20.2399 1.52222 18.0964 0.23299 15.7051 0.0113474C14.432 -0.107445 13.2485 0.717417 12.8891 1.97116C12.2121 4.32798 12.5961 6.84164 13.943 8.8683C15.2899 10.8939 17.4335 12.1832 19.8242 12.4053C19.9056 12.413 19.9861 12.4166 20.0665 12.4166C21.244 12.4166 22.3035 11.619 22.6402 10.4455C23.3172 8.08767 22.9332 5.57401 21.5868 3.54837ZM20.2293 9.72042C20.2077 9.79653 20.1313 9.85516 20.0514 9.84384C18.412 9.69162 16.9429 8.80865 16.0207 7.42171C15.0985 6.03426 14.8356 4.31152 15.3 2.69523C15.3206 2.62323 15.391 2.5713 15.4638 2.5713C15.4684 2.5713 15.4734 2.57129 15.4779 2.57181C17.1173 2.72403 18.5864 3.607 19.5086 4.99445C20.4308 6.38139 20.6937 8.10361 20.2293 9.72042Z'
              fill='#222122'
            />
          </svg>
        </>
      ) : (
        <>
          <svg
            width='39'
            height='48'
            viewBox='0 0 39 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M30.6485 9.50084C28.9754 9.08326 27.2355 9.20205 25.478 9.85413C21.6232 11.2837 17.3769 11.2837 13.5221 9.85413C11.7651 9.20205 10.0251 9.08326 8.35105 9.50084C1.59537 11.1886 -1.69201 20.9116 0.866629 31.6358C3.17096 41.2939 9.31651 47.9957 15.4721 47.9947C16.1516 47.9947 16.8326 47.9129 17.5071 47.7442C18.8298 47.4084 20.1702 47.4084 21.5227 47.7519C22.1926 47.9191 22.8671 48.0003 23.542 48.0003C29.678 48.0003 35.8281 41.2975 38.1334 31.6358C40.6921 20.9116 37.4042 11.1886 30.6485 9.50084ZM35.6919 31.0264C33.5414 40.0392 27.3275 46.5547 22.0981 45.2491C20.3738 44.8109 18.6258 44.8114 16.8818 45.2542C11.6736 46.5542 5.4587 40.0392 3.30816 31.0264C1.15762 22.0131 3.73988 13.2996 8.94711 11.9991C9.39893 11.8859 9.8613 11.8299 10.3312 11.8299C11.0916 11.8299 11.8721 11.9775 12.6662 12.2716C17.0738 13.9059 21.9277 13.9064 26.3344 12.2716C27.6185 11.7949 28.8694 11.7039 30.053 11.9991C35.2597 13.2996 37.8424 22.0131 35.6919 31.0264Z'
              fill='#222122'
            />
            <path
              d='M21.0868 3.54837C19.7399 1.52222 17.5964 0.23299 15.2051 0.0113474C13.932 -0.107445 12.7485 0.717417 12.3891 1.97116C11.7121 4.32798 12.0961 6.84164 13.443 8.8683C14.7899 10.8939 16.9335 12.1832 19.3242 12.4053C19.4056 12.413 19.4861 12.4166 19.5665 12.4166C20.744 12.4166 21.8035 11.619 22.1402 10.4455C22.8172 8.08767 22.4332 5.57401 21.0868 3.54837ZM19.7293 9.72042C19.7077 9.79653 19.6313 9.85516 19.5514 9.84384C17.912 9.69162 16.4429 8.80865 15.5207 7.42171C14.5985 6.03426 14.3356 4.31152 14.8 2.69523C14.8206 2.62323 14.891 2.5713 14.9638 2.5713C14.9684 2.5713 14.9734 2.57129 14.9779 2.57181C16.6173 2.72403 18.0864 3.607 19.0086 4.99445C19.9308 6.38139 20.1937 8.10361 19.7293 9.72042Z'
              fill='#222122'
            />
            <path
              d='M29.9189 11.1992C28.5166 10.8464 27.0698 10.9713 25.6511 11.5018C21.6723 12.9889 17.3277 12.9889 13.3489 11.5018C11.9301 10.9713 10.4834 10.8464 9.08105 11.1992C3.33942 12.645 0.518793 21.5627 2.7806 31.1174C5.04241 40.6726 11.5306 47.2461 17.2717 45.8008C17.2799 45.7988 17.2877 45.7968 17.2959 45.7948C18.7498 45.4225 20.2502 45.4225 21.7041 45.7948C21.7123 45.7968 21.7201 45.7988 21.7283 45.8008C27.4699 47.2461 33.9576 40.6726 36.2194 31.1174C38.4812 21.5627 35.6606 12.645 29.9189 11.1992Z'
              fill='#222122'
            />
            <path
              d='M20.6935 4.47664C19.5215 3.00684 17.688 2.15423 15.763 2.00495C15.0905 1.95291 14.464 2.31631 14.2735 2.86908C13.729 4.45104 13.9845 6.21897 15.1565 7.68919C16.3285 9.15898 18.162 10.0116 20.087 10.1604C20.7595 10.2125 21.386 9.84909 21.576 9.29632C22.121 7.71478 21.8655 5.94643 20.6935 4.47664Z'
              fill='#222122'
            />
          </svg>
        </>
      )}
    </>
  )
}
