// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".statistic-chart-local_local__xCmWw {\n  margin-top: 50px;\n  margin-bottom: 100px;\n}\n.statistic-chart-local_local_Title__TCgOr {\n  color: #222122;\n  font-size: 40px;\n  font-weight: 500;\n}\n.statistic-chart-local_local_Details__AxgWQ {\n  color: #5b5b5b;\n  font-size: 24px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/features/statistics/components/statistici-chart-local/statistic-chart-local.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,oBAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".local {\n  margin-top: 50px;\n  margin-bottom: 100px;\n\n  &_Title {\n    color: #222122;\n    font-size: 40px;\n    font-weight: 500;\n  }\n\n  &_Details {\n    color: #5b5b5b;\n    font-size: 24px;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"local": "statistic-chart-local_local__xCmWw",
	"local_Title": "statistic-chart-local_local_Title__TCgOr",
	"local_Details": "statistic-chart-local_local_Details__AxgWQ"
};
export default ___CSS_LOADER_EXPORT___;
