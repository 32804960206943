// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".results_result_ModalBody__ngu18 {\n  height: 600px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: 100%;\n  flex-direction: column;\n}\n.results_result_Title__A2DrA {\n  color: #5b5b5b;\n  font-size: 24px;\n  font-weight: 500;\n  margin-bottom: 32px;\n  line-height: 32px;\n}\n.results_result_RegisterText__aSU41 {\n  margin-top: 40px;\n  color: #5b5b5b;\n  line-height: 32px;\n  font-weight: 400;\n  font-size: 20px;\n}", "",{"version":3,"sources":["webpack://./src/features/quiz-sections/components/results/results.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,WAAA;EACA,sBAAA;AAAJ;AAGE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AADJ;AAIE;EACE,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AAFJ","sourcesContent":[".result {\n  &_ModalBody {\n    height: 600px;\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 100%;\n    flex-direction: column;\n  }\n\n  &_Title {\n    color: #5b5b5b;\n    font-size: 24px;\n    font-weight: 500;\n    margin-bottom: 32px;\n    line-height: 32px;\n  }\n\n  &_RegisterText {\n    margin-top: 40px;\n    color: #5b5b5b;\n    line-height: 32px;\n    font-weight: 400;\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result_ModalBody": "results_result_ModalBody__ngu18",
	"result_Title": "results_result_Title__A2DrA",
	"result_RegisterText": "results_result_RegisterText__aSU41"
};
export default ___CSS_LOADER_EXPORT___;
