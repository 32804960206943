// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".statistic-chart-date_dateChart__vdPSU {\n  width: 100%;\n  margin-top: 50px;\n  margin-bottom: 100px;\n}\n.statistic-chart-date_dateChart_Header__3P4E5 {\n  display: flex;\n  width: 100%;\n}\n.statistic-chart-date_dateChart_Details__JAK-w {\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n  margin-left: 32px;\n}", "",{"version":3,"sources":["webpack://./src/features/statistics/components/statistic-chart-date/statistic-chart-date.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".dateChart {\n  width: 100%;\n  margin-top: 50px;\n  margin-bottom: 100px;\n\n  &_Header {\n    display: flex;\n    width: 100%;\n  }\n\n  &_Details {\n    font-size: 24px;\n    font-weight: 500;\n    line-height: 32px;\n    margin-left: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateChart": "statistic-chart-date_dateChart__vdPSU",
	"dateChart_Header": "statistic-chart-date_dateChart_Header__3P4E5",
	"dateChart_Details": "statistic-chart-date_dateChart_Details__JAK-w"
};
export default ___CSS_LOADER_EXPORT___;
