export const SimpleLogo = () => {
  return (
    <svg width='81' height='78' viewBox='0 0 81 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M52.869 21.5761C41.9776 15.1044 27.9226 18.5438 21.5697 29.2352C21.1433 29.9527 20.7771 30.7989 20.3957 31.6603C20.205 32.091 20.0289 32.4992 19.8949 32.8157C19.8279 32.974 19.7709 33.1076 19.7262 33.2169C19.6898 33.3057 19.6673 33.3521 19.6164 33.4993C19.6145 33.5046 19.6126 33.5134 19.6107 33.5187C17.4167 39.6274 15.797 50.1862 17.6599 60.6978H23.6455C21.9433 51.0925 22.5316 40.4816 24.4101 35.2594L24.4234 35.2273L24.4323 35.1928C24.3508 35.4415 24.4127 35.2613 24.4466 35.1785C24.4806 35.0956 24.5281 34.9756 24.5896 34.8304C24.7126 34.5399 24.8805 34.1506 25.0589 33.7474C25.4159 32.9411 25.8957 31.9582 25.9627 31.8455C30.9305 23.485 41.7897 20.8277 50.3065 25.8885C55.4688 28.9559 57.6723 32.5062 58.6608 35.8898L63.5463 34.5647C62.2308 30.0617 59.0362 25.2407 52.869 21.5761ZM47.2862 29.9458C46.9121 29.7431 46.526 29.5554 46.1261 29.3875C39.6354 26.6695 31.5594 29.6042 28.8771 37.0418L33.6683 38.8061C35.446 33.8769 40.2908 32.3824 44.2089 34.0206C48.0819 35.6465 49.9603 39.9982 48.4516 43.8296L48.4359 43.8659L48.4244 43.9046C48.0376 44.9943 43.539 49.4333 50.5808 60.6978H55.8182C49.7561 51.0006 53.1608 45.823 53.2239 45.6453C55.5094 39.7208 52.8717 32.9718 47.2862 29.9458ZM63.6935 44.7209L58.7672 43.3891C58.4249 44.7113 58.006 46.3097 57.7473 47.9286C57.4887 49.5475 57.2989 51.1757 57.8701 52.9191L62.7059 51.4335C62.6243 51.1844 62.5664 50.074 62.7709 48.7939C62.9754 47.5138 63.3498 46.0486 63.6935 44.7209ZM43.6933 41.9709L38.9829 39.9925C38.9829 39.9925 31.4266 45.1449 40.106 60.6978H45.3434C37.654 46.9188 43.6933 41.9709 43.6933 41.9709ZM32.4677 46.4634L27.3991 45.9474C26.9942 50.5839 26.8492 53.9896 28.883 60.6978L33.3722 60.6978C31.5522 54.6949 32.1087 50.5747 32.4677 46.4634Z'
        fill='#509046'
      />
      <path
        d='M26.6383 17.3022L21.4014 17.3022C21.0591 18.6244 20.2944 22.2919 20.0357 23.9108C19.777 25.5297 18.2037 38.4379 17.8309 40.915L24.9943 27.4157C24.9127 27.1666 24.8547 26.0562 25.0592 24.7761C25.2638 23.496 26.2947 18.6299 26.6383 17.3022Z'
        fill='#509046'
      />
    </svg>
  )
}
