// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home-chart-local_local__RP5zG {\n  margin-top: 50px;\n  margin-bottom: 100px;\n}\n.home-chart-local_local_Title__ZjhDt {\n  color: #222122;\n  font-size: 40px;\n  font-weight: 500;\n}\n.home-chart-local_local_Details__exe81 {\n  color: #5b5b5b;\n  font-size: 24px;\n  font-weight: 500;\n}\n.home-chart-local_local_InputContainer__AmpG6 {\n  display: flex;\n  width: 93%;\n  justify-content: flex-end;\n  margin: 0;\n}\n.home-chart-local_local_Input__CZe9V {\n  margin: 0;\n  display: flex;\n  width: 260px;\n  height: 32px;\n  position: relative;\n  border-radius: 8px;\n  border: 2px solid #222122;\n  background: #fffef7;\n  margin-top: 6px;\n  cursor: pointer;\n  padding: 0 16px;\n}", "",{"version":3,"sources":["webpack://./src/features/home/components/home-chart-global/home-chart-local.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,oBAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,UAAA;EACA,yBAAA;EACA,SAAA;AADJ;AAIE;EACE,SAAA;EAEA,aAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,eAAA;AAFJ","sourcesContent":[".local {\n  margin-top: 50px;\n  margin-bottom: 100px;\n\n  &_Title {\n    color: #222122;\n    font-size: 40px;\n    font-weight: 500;\n  }\n\n  &_Details {\n    color: #5b5b5b;\n    font-size: 24px;\n    font-weight: 500;\n  }\n\n  &_InputContainer {\n    display: flex;\n    width: 93%;\n    justify-content: flex-end;\n    margin: 0;\n  }\n\n  &_Input {\n    margin: 0;\n    display: -ms-flexbox;\n    display: flex;\n    width: 260px;\n    height: 32px;\n    position: relative;\n    border-radius: 8px;\n    border: 2px solid #222122;\n    background: #fffef7;\n    margin-top: 6px;\n    cursor: pointer;\n    padding: 0 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"local": "home-chart-local_local__RP5zG",
	"local_Title": "home-chart-local_local_Title__ZjhDt",
	"local_Details": "home-chart-local_local_Details__exe81",
	"local_InputContainer": "home-chart-local_local_InputContainer__AmpG6",
	"local_Input": "home-chart-local_local_Input__CZe9V"
};
export default ___CSS_LOADER_EXPORT___;
